var drawoptions = new Object;
var point = new Object;
function Point(x, y, pressure) {
  this.x=x;
  this.y=y;
  this.pressure=pressure;
}

drawoptions.mousedown=false;
var zl=0;
var last_pos;
var points = [];
var points_new = [];
var vueApp;

var shared_data = {
  last_checkin: { checkin_ts: 0 },
  users_by_id: {}
};

//const $ = require('jquery');
//import jQuery from 'jquery';
//import $ from 'jquery';
//const jQuery = $;
//const $ = jQuery;
//const $=jQuery;

/*
    <link rel="stylesheet" type="text/css" href="jquery.datetimepicker.min.css">
    <script src="core.min.js"></script>
    <script src="jquery.min.js"></script>
    <script src="moment.min.js"></script>
    <script src="moment-duration-format.min.js"></script>
    <script src="socket.io/socket.io.js"></script>
    <script src="@feathersjs/client/dist/feathers.js"></script>
    <script src="vue-datetime-picker.js"></script>
    <script src="jquery.datetimepicker.full.min.js"></script>
    <script src="draw.js"></script>
    <script src="app.js"></script>
*/

/*
jQuery.datetimepicker = require('jquery.datetimepicker.full.js');

jQuery.datetimepicker.setLocale('de');
jQuery.datetimepicker.setDateFormatter({
    parseDate: function (date, format) {
        var d = moment(date, format);
        return d.isValid() ? d.toDate() : false;
    },
    formatDate: function (date, format) {
        return moment(date).format(format);
    }
});
*/

const feathers = require('@feathersjs/feathers');
feathers.socketio = require('@feathersjs/socketio-client');
feathers.authentication = require('@feathersjs/authentication-client');

//const Vue = require('vue');
import Vue from 'vue';
import { Datetime } from 'vue-datetime';
import { Settings } from 'luxon';
Settings.defaultLocale = 'de';

Datetime.created = function() {
  //console.log("Datetime created");
}

import fontawesome from '@fortawesome/fontawesome';
import FontAwesomeIcon from '@fortawesome/vue-fontawesome';
import faSpinner from '@fortawesome/fontawesome-free-solid/faSpinner';
import faArrowLeft from '@fortawesome/fontawesome-free-solid/faArrowLeft';
import faPlus from '@fortawesome/fontawesome-free-solid/faPlus';
import faTrashAlt from '@fortawesome/fontawesome-free-solid/faTrashAlt';

fontawesome.library.add(faSpinner, faArrowLeft, faPlus, faTrashAlt);

const moment = require('moment');
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

require('moment/locale/de');

moment.locale('de');

/*
const io = require('socket.io-client');
const feathers = require('@feathersjs/client');
const moment = require('moment');
const duration = require('moment-duration-format');
//const Vue = require('vue');
import Vue from 'vue/dist/vue.js';
*/
/*
luxon.Settings.defaultLocale = 'de';
*/
/*
console.log(Datetime);

*/
/*
VueDatetime.Datetime.created = function() {
  console.log("Datetime created");
}
*/
//const vue_datetime_picker = require("vue-datetime-picker");

//import vue_datetime_picker from "vue-datetime-picker";
//const socketio = require('@feathersjs/socketio-client');


//var io = require('socket.io');

$('#app').html("loading ...");
$('#app').html('<component v-bind:is="currentPage" v-bind:loginerror="loginerror" v-bind:visible="visible"></component>');

//const socket = io();

const socket = io({
  transports: ['websocket']
});

// on reconnection, reset the transports option, as the Websocket
// connection may have failed (caused by proxy, firewall, browser, ...)
//socket.on('reconnect_attempt', () => {
//  socket.io.opts.transports = ['polling', 'websocket'];
//});

const client = feathers();

client.configure(feathers.socketio(socket));

/*
var restClient = feathers.rest('https://an.parsec.co.at')
client.configure(restClient.jquery(window.jQuery));
*/
client.configure(feathers.authentication({
  storage: window.localStorage,
}));

Vue.component('font-awesome-icon', FontAwesomeIcon);

//Vue.component('datetime', VueDatetime.Datetime);
Vue.component('datetime', Datetime);

Vue.component('app-head', {
  props: ['title', 'logo', 'back'],
  template: `
    <header class="title-bar">
      <div class="left">
        <button v-if="back" type="button" class="app-back" v-on:click="$emit('back')"><font-awesome-icon icon="arrow-left" /></button>
        <span class="title">{{ title }}</span>
      </div>
      <div class="right">
        <img class="logo" v-bind:src="logo" alt="parsec">
      </div>
      <div style="clear:both"></div>
    </header>`
});

Vue.component('app-zulage', {
  template: `
    <div class="flex flex-row row pad-top-5">
      <div class="flex flex-column col col-9">
        <select name="zulagen" v-bind:id="'zulage-' + index" v-model="zulage.zulage_id">
          <option v-for="z in sd.zulagen" v-bind:value="z.id">{{z.text}}</option>
        </select>
      </div>
      <div class="flex flex-column col col-3">
        <button type="button" v-bind:id="'zulage-minus-' + index" v-on:click="$emit('remove')" class="button button-primary button-small zulage-minus"><font-awesome-icon icon="trash-alt" /></button>
      </div>
    </div>`,
  props: ['zulage', 'index'],
  data: function() {
    return {
      sd: shared_data
    }
  }
});

Vue.component('app-zulagen', {
  template: `
    <div class="zulagen">
      <div v-for="(zulage, index) in zulagen">
        <app-zulage v-bind:zulage="zulage" v-bind:index="index" v-on:remove="zulagen.splice(index,1); $emit('change')"></app-zulage>
      </div>
    </div>
`,
  props: [ 'zulagen' ]
});

Vue.component('app-user', {
  data: function() {
    return { name: client.get('user').vorname+" "+client.get('user').nachname };
  },
  template: `
    <app-full-line class="pad-top-15">
        <div class="user" style="border:1px solid #c1c1c1;padding: 5px;">{{ name }}</div>
    </app-full-line>
`});

Vue.component('app-checkin-ts', {
  computed: {
    ts_txt: function() {
      if(!this.sd.last_checkin.checkin_ts)
        return '';
      var now = moment();
      var checkin_ts = moment(this.sd.last_checkin.checkin_ts);
      var duration = moment.duration(now.diff(checkin_ts));
      var duration_txt = `${checkin_ts.format('DD.MM.YYYY')}`;
      return duration_txt;
    }
  },
  data: function() { return { sd: shared_data } },
  template: `
    <app-full-line>
        <div style="border:1px solid #c1c1c1;padding: 5px;">{{ ts_txt }}&nbsp;</div>
    </app-full-line>
`});

Vue.component('app-full-line', {
  template: `
    <div class="flex flex-row row clear">
      <div class="flex flex-column col col-12">
        <slot></slot>
      </div>
    </div>
`});

Vue.component('app-button', {
  props: {
    type: { type: String, default: 'button' },
    caption: String,
    id: String,
    addclass: String,
    onclick: { type: Function, required: false }
  },
  template: `
    <app-full-line class="pad-top-15">
        <button v-bind:id="id" class="button-primary" v-bind:class="addclass" v-bind:type="type" v-on:click="onclick" v-if="onclick">{{ caption }}<slot></slot></button>
        <button v-bind:id="id" class="button-primary" v-bind:class="addclass" v-bind:type="type" v-else>{{ caption }}<slot></slot></button>
    </app-full-line>
`});

const registerHTML = `
      <div class="container-m container-full">
        <header class="title-bar">
          <div class="left">
            <span class="title">Registrierung</span>
          </div>
          <div class="right">
            <img class="logo" src="parsec.png" alt="parsec">
          </div>
          <div style="clear:both"></div>
        </header>
        <div id="content">
          <div class="flex flex-row row pad-top-15">
            <div class="flex flex-column col col-12">
              <p>Hier registrieren Sie sich für die Parsec Anwesenheitsbestätigung. Bitte verwenden Sie zur Registrierung eine E-Mail Adresse, die Sie regelmäßig prüfen. In Zukunft werden die Abrechnungen an diese Adresse geschickt.</p>
              <p>Die Registrierung muss über einen Link, den Sie zugeschickt bekommen, bestätigt werden.</p>
            </div>
          </div>

          <form id="registerform">
            <div class="flex flex-row row pad-top-5">
              <label class="col-12">Vorname</label>
            </div>
            <div class="flex flex-row row flex-1 clear">
              <div class="flex flex-column col col-12">
                <input type="text" name="vorname" class="flex flex-1" required>
              </div>
            </div>
            <div class="flex flex-row row pad-top-5">
              <label class="col-12">Nachname</label>
            </div>
            <div class="flex flex-row row flex-1 clear">
              <div class="flex flex-column col col-12">
                <input type="text" name="nachname" class="flex flex-1" required>
              </div>
            </div>
            <div class="flex flex-row row pad-top-5">
              <label class="col-12">E-mail</label>
            </div>
            <div class="flex flex-row row flex-1 clear">
              <div class="flex flex-column col col-12">
                <input type="email" id="email" name="email" class="flex flex-1" required>
              </div>
            </div>
            <div class="flex flex-row row pad-top-5">
              <label class="col-12" for="password">Passwort</label>
            </div>
            <div class="flex flex-row row flex-1 clear">
              <div class="flex flex-column col col-12">
                <input type="password" name="password" id="password" class="flex flex-1" required minlength="6">
              </div>
            </div>
            <div class="flex flex-row row pad-top-5">
              <label class="col-12" for="password2">Passwort wiederholen</label>
            </div>
            <div class="flex flex-row row flex-1 clear">
              <div class="flex flex-column col col-12">
                <input type="password" name="password2" id="password2" class="flex flex-1" required minlength="6">
              </div>
            </div>
            <div id="errors">
            </div>
            <div class="flex flex-row row flex-2 clear pad-top-15">
              <div class="flex flex-column col col-12">
                <button type="submit" class="button-primary" id="register">Registrieren</button>
              </div>
            </div>
          </div>
        </form>
      </div>
`;

const loginHTML = `
      <div class="container-m container-full">
        <app-head v-bind:logo="logo" v-bind:title="title"></app-head>
        <form class="login" v-on:submit="on_submit">
          <div class="flex flex-row row pad-top-5">
            <label class="col-12">E-mail</label>
          </div>
          <div class="flex flex-row row flex-1 clear">
            <div class="flex flex-column col col-12">
              <input type="email" id="email" name="email" v-model="email" class="flex flex-1" autocomplete="username email">
            </div>
          </div>
          <div class="flex flex-row row pad-top-5">
            <label class="col-12" for="password">Passwort</label>
          </div>
          <div class="flex flex-row row flex-1 clear">
            <div class="flex flex-column col col-12">
              <input type="password" name="password" id="password" v-model="password" class="flex flex-1" autocomplete="current-password">
            </div>
          </div>
          <div class="flex flex-row row flex-1">
            <div class="flex flex-column col col-12 errors">
            </div>
          </div>
          <div v-if="loginerror">
            <app-full-line class="pad-top-15">Brauchen Sie ein neues Passwort? Klicken sie unten auf 'Passwort zurücksetzen' und wir schicken Ihnen einen Link an die oben genannte Adresse.</app-full-line>
            <div class="flex flex-row row clear pad-top-15">
              <div class="flex flex-column col col-12">
                <button type="button" class="button-primary" v-on:click="reset_password_link">Passwort zurücksetzen</button>
              </div>
            </div>
          </div>
          <div class="flex flex-row row clear pad-top-15">
            <div class="flex flex-column col col-12">
              <button type="submit" class="button-primary" id="login">Anmelden</button>
            </div>
          </div>
        </form>
      </div>
`;

const menuHTML = `
      <div class="container-m container-full">
        <app-head v-bind:logo="logo" v-bind:title="title"></app-head>
        <app-user></app-user>
<!--
        <div class="flex flex-row row clear pad-top-15">
          <div class="flex flex-column col col-12">
             <div style="border:1px solid #c1c1c1;padding: 5px;">Pos: <span id="pos_data"></span></div>
          </div>
        </div>
-->
        <form v-on:submit="checkin_submit">
<!--
          <app-button id="checkin" type="submit" caption="Check-in"></app-button>
          <app-checkin-ts class="pad-top-15"></app-checkin-ts>
-->
          <app-button id="checkout" type="button" caption="Anwesenheitsbestätigung" :onclick="click_checkout"></app-button>
        </form>
        <app-button type="button" caption="Arbeitsnachweise" :onclick="click_list"></app-button>
        <app-button type="button" caption="Admin" v-if="user_is_admin" :onclick="click_admin"></app-button>
        <app-button type="button" caption="Tabelle" v-if="user_is_admin" :onclick="click_table"></app-button>

        <app-full-line class="pad-top-15"><div>Nur auf fremden Geräten:</div></app-full-line>
        <app-button id="logout" addclass="button-logout" type="button" :onclick="click_logout" caption="Abmelden"></app-button>
      </div>
`;

const checkoutHTML = `
      <div class="container-m container-full">
        <app-head v-bind:logo="logo" v-bind:title="title" :back="true" @back="click_back"></app-head>
        <app-user></app-user>
<!--
        <div class="flex flex-row row clear pad-top-15">
          <div class="flex flex-column col col-12">
             <div style="border:1px solid #c1c1c1;padding: 5px;">Pos: <span id="pos_data"></span></div>
          </div>
        </div>
-->
        <form v-on:submit="on_submit">
          <div class="flex flex-row row pad-top-5">
            <label class="col-12">Datum</label>
          </div>
          <app-checkin-ts></app-checkin-ts>
          <div class="flex flex-row row pad-top-5">
            <label class="col-12" for="produktionsort">Produktionsort (Ort, Land)</label>
          </div>
          <div class="flex flex-row row flex-1 clear">
            <div class="flex flex-column col col-12">
              <input type="text" name="drehort" v-model="sd.last_checkin.produktionsort" placeholder="Ort" id="produktionsort" class="flex flex-1" list="ort-list" required>
              <datalist id="ort-list">
                <option value="ORF Zentrum"></option>
                <option value="Stadtstudio"></option>
              </datalist>
            </div>
          </div>
          <div class="flex flex-row row flex-1 clear">
            <div class="flex flex-column col col-12">
                <label for="mittepause">Mittepause</label>
            </div>
          </div>
          <div class="flex flex-row row flex-1 clear">
            <div class="flex flex-column col col-12">
              <input type="number" name="mittepause" v-model="sd.last_checkin.mittepause" id="mittepause" class="flex flex-1" step="30" min="0" max="120" list="mittepausedauer">
              <datalist id="mittepausedauer">
                <option value="0"></option>
                <option value="30"></option>
                <option value="60"></option>
                <option value="90"></option>
                <option value="120"></option>
              </datalist>
            </div>
          </div>
          <div class="flex flex-row row pad-top-5">
            <label class="col-12" for="km">gefahrene km</label>
          </div>
          <div class="flex flex-row row flex-1 clear">
            <div class="flex flex-column col col-12">
              <input type="number" name="km" id="km" v-model="sd.last_checkin.km" class="flex flex-1">
            </div>
          </div>
          <div class="flex flex-row row pad-top-5">
            <label class="col-12" for="km">MitfahrerInnen</label>
          </div>
          <div class="flex flex-row row flex-1 clear">
            <div class="flex flex-column col col-12">
              <input type="number" v-model="sd.last_checkin.mitfahrer" class="flex flex-1">
            </div>
          </div>
          <div class="flex flex-row row flex-1 clear">
            <div class="flex flex-column col col-12">
              <span class="checkbox">
                <label for="naechtigung">Nächtigung</label>
                <input type="checkbox" name="naechtigung" v-model="sd.last_checkin.naechtigung" id="naechtigung">
              </span>
            </div>
          </div>
          <div class="flex flex-row row pad-top-5">
            <label class="col-12" for="zulagen">Zulagen</label>
          </div>
          <app-zulagen v-bind:zulagen="sd.last_checkin.zulagen"></app-zulagen>
          <div class="flex flex-row row pad-top-10">
            <div class="flex flex-column col col-12">
              <button type="button" id="zulage-plus" v-on:click="zulage_add" class="button button-primary button-small"><font-awesome-icon icon="plus" /></button>
            </div>
          </div>
          <div class="flex flex-row row pad-top-5">
            <label class="col-12" for="zusatzleistungen">Anmerkungen / zusätzliche Leistungen</label>
          </div>
          <div class="flex flex-row row flex-1 clear">
            <div class="flex flex-column col col-12">
              <input type="text" name="zusatzleistungen" id="zusatzleistungen" v-model="sd.last_checkin.zusatzleistungen" placeholder="Anmerkungen, zusätzliche Leistungen" class="flex flex-1">
            </div>
          </div>
          <div class="flex flex-row row pad-top-5">
            <label class="col-12" for="redakteurin-pl">Name DTL / PL</label>
          </div>
          <div class="flex flex-row row clear">
            <div class="flex flex-column col col-12">
              <input type="text" name="redakteurin-pl" id="redakteurin-pl" v-model="sd.last_checkin.redakteurin_pl" class="flex flex-1" required>
            </div>
          </div>
          <div class="flex flex-row row clear pad-top-15 pad-bottom-15">
            <div class="flex flex-column col col-6">
              <button id="checkout-back" v-on:click="click_back" class="button-primary" type="button">Zurück</button>
            </div>
            <div class="flex flex-column col col-6">
              <button id="btn-checkout-2" type="submit" class="button-primary">Weiter …</button>
            </div>
          </div>
        </form>
      </div>
`;

const checkout2HTML = `
    <div style="display:flex;flex-direction:column;height:100%">
      <div class="container-full" style="width:100%">
        <app-head v-bind:logo="logo" v-bind:title="title" :back="true" @back="click_back"></app-head>
        <div class="flex flex-row row pad-top-5">
          <label class="col-12" id="summary">{{ summary }}</label>
        </div>
      </div>
      <div class="container-full" style="display:flex;flex: 1 1 auto;flex-direction:column;min-height:220px;width:100%;">
        <div class="flex flex-row pad-top-5" style="flex: 0 0 auto">
          <label for="csig" id="label-sig">Unterschrift ({{ sd.last_checkin.redakteurin_pl }})</label>
        </div>
        <div class="flex flex-row" style="flex:1 1 auto; width:100%;">
          <svg xmlns="http://www.w3.org/2000/svg" id="csig" style="border:1px solid #c1c1c1; flex: 1 1 auto; width:100%;"><path stroke-width="1" stroke="black" fill="transparent"/></svg>
        </div>
      </div>
      <form class="container-m container-full" v-on:submit="on_submit">
        <div class="container-full">
          <div class="flex flex-row pad-top-5">
            <div>Ich bestätige, dass der Mitarbeiter {{ name }} am {{ checkout_date }} am o. a. Ort anwesend war.</div>
          </div>
        </div>
        <div class="flex flex-row row clear pad-top-15 pad-bottom-15">
          <div class="flex flex-column col col-4">
            <button id="sig-back" class="button-primary" v-on:click="click_back" type="button">Zurück</button>
          </div>
          <div class="flex flex-column col col-4">
            <button id="clearsig" class="button-primary" v-on:click="clear_sig" type="button">Löschen</button>
          </div>
          <div class="flex flex-column col col-4">
            <button id="save" class="button-primary" type="submit">Sichern</button>
          </div>
        </div>
      </form>
    </div>
`;

Vue.component('app-loading', {
  template: `
      <div class="container-m container-full">
        <app-head v-bind:logo="logo" v-bind:title="title"></app-head>
        <app-full-line class="pad-top-15">laden ...</app-full-line>
      </div>
`,
  data: function() {
    return {
      logo: 'parsec.png',
      title: 'Anwesenheit ORF',
      sd: shared_data,
    }
  }
});

Vue.component('app-login', {
  template: loginHTML,
  props: ['loginerror'],
  data: function() {
    return {
      logo: 'parsec.png',
      title: 'Login',
      email: '',
      password: '',
    }
  },
  methods: {
    on_submit: async function(ev) {
      ev.preventDefault();
      console.log(this);
      const user = {
        email: this.email,
        password: this.password
      };

      await login(user);
    },
    reset_password_link: async function() {
      try {
        await client.service('authManagement').create({action: 'sendResetPwd', value: { email: this.email }});
        $('.errors').html('<p>Der Link zum Zurücksetzen des Passworts wurde versandt.</p>');
      } catch(error) {
        $('.errors').html(error.message);
      }
      this.$root.loginerror = false;
    }
  }
});

Vue.component('app-menu', {
  template: menuHTML,
  props: ['visible'],
  data: function() {
    return {
      logo: 'parsec.png',
      title: 'Anwesenheit ORF',
      sd: shared_data,
      user_is_admin: false,
    }
  },
  methods: {
    click_logout: async function(ev) {
      await client.logout();
      vueApp.currentPage="app-login";
      vueApp.loginerror = false;
    },
    click_list: async function(ev) {
      vueApp.currentPage="app-list";
    },
    click_admin: async function(ev) {
      vueApp.currentPage="app-admin";
    },
    click_table: async function(ev) {
      vueApp.currentPage="app-table";
    },
    click_checkout: async function (ev) {
      if(!this.sd.last_checkin.checkin_ts)
      {
        var data = { checkin: true };
        if(last_pos && last_pos.coords.latitude && last_pos.coords.longitude) {
          data = {
            checkin_pos_lat: last_pos.coords.latitude,
            checkin_pos_lon: last_pos.coords.longitude,
            checkin_pos_acc: last_pos.coords.accuracy,
            checkin_pos_ts: last_pos.timestamp
          };
        }
        this.sd.last_checkin = await client.service('zeiterfassung').create(data);
      }
      this.$root.currentPage= 'app-checkout';
    },
    checkin_submit: async function(ev) {
      ev.preventDefault();
      var data = { checkin: true };
      if(last_pos && last_pos.coords.latitude && last_pos.coords.longitude) {
        data = {
          checkin_pos_lat: last_pos.coords.latitude,
          checkin_pos_lon: last_pos.coords.longitude,
          checkin_pos_acc: last_pos.coords.accuracy,
          checkin_pos_ts: last_pos.timestamp
        };
      }
      var ret = await client.service('zeiterfassung').create(data);
      console.log(ret);
      this.refresh();
    },
    refresh: async function() {
      console.log('refresh()');
      await getLastCheckin();
/*
      if(this.sd.last_checkin.checkin_ts != null && this.sd.last_checkin.checkout_ts === null)
      {
        $('#checkin').addClass('disabled');
        $('#checkout').removeClass('disabled');
      } else {
        $('#checkout').addClass('disabled');
        $('#checkin').removeClass('disabled');
      }
*/
    }
  },
  watch: {
    visible: function(v) {
      console.log("app-menu watch visible=" + v);
      if(v)
        this.refresh();
    }
  },
  mounted: async function() {
    console.log("app-menu mounted");
    this.refresh();
    this.user_is_admin = client.get('user').role==255;
  },
  created: function() {
    console.log("app-menu created");
  }
});

Vue.component('app-checkout', {
  template: checkoutHTML,
  data: function() {
    return {
      logo: 'parsec.png',
      title: 'Anwesenheit ORF',
      sd: shared_data,
    }
  },
  methods: {
    save: async function() {
      var data = {}

      if(last_pos && last_pos.coords.latitude && last_pos.coords.longitude) {
        data = Object.assign(data, {
          checkout_pos_lat: last_pos.coords.latitude,
          checkout_pos_lon: last_pos.coords.longitude,
          checkout_pos_acc: last_pos.coords.accuracy,
          checkout_pos_ts: last_pos.timestamp
        });
      }

      try {
        var last_checkin = await client.service('zeiterfassung').patch(shared_data.last_checkin.id, shared_data.last_checkin);
      } catch (error) {
        console.log(error);
        $('.zulagen').addClass('mark');
        return false;
      }
      return last_checkin;
    },
    zulage_add: async function(ev) {
      shared_data.last_checkin.zulagen.push( { zeiterfassung_id: shared_data.last_checkin.id, zulage_id: null } );
      this.$nextTick(function() {
        var zl_els = document.getElementsByName('zulagen');
        zl_els[zl_els.length-1].focus();
      });
    },
    click_back: async function(ev) {
      if(await this.save())
        this.$root.currentPage='app-menu';
    },
    on_submit: async function(ev) {
      ev.preventDefault();
      if(await this.save())
        this.$root.currentPage='app-checkout-sig';
    },
  },
  mounted: function() {
    console.log('app-checkout mounted');
    var now = moment();
    var checkin_ts = moment(this.sd.last_checkin.checkin_ts);
    var duration = moment.duration(now.diff(checkin_ts));
    //if(duration.as('hours') > 6.0) {
      if(this.sd.last_checkin.mittepause == null) {
        this.sd.last_checkin.mittepause = 30;
      }
    //}
  },
});

Vue.component('app-checkout-sig', {
  template: checkout2HTML,
  data: function() {
    var last_checkin = shared_data.last_checkin;
    var zulagen_txt = last_checkin.zulagen.map(function(z) {
      return shared_data.zulagen.find(function(f) { return f.id == z.zulage_id }).text;
    }).join(', ');
    if(zulagen_txt)
      zulagen_txt += '; ';
    var now = moment();
    var checkin_ts = moment(last_checkin.checkin_ts);
    var duration = moment.duration(now.diff(checkin_ts));
    var duration_txt = `${checkin_ts.format('DD.MM.YYYY')}`;
    var summary = `${client.get('user').vorname} ${client.get('user').nachname}: ${duration_txt}; Ort: ${last_checkin.produktionsort}; ${( last_checkin.mittepause ? 'Mittepause: ' + last_checkin.mittepause + 'min; ' : '' )}${(last_checkin.km ? last_checkin.km + 'km; ' : '')}${( last_checkin.naechtigung ? 'Nächtigung; ' : '' )}${zulagen_txt}${(last_checkin.zusatzleistungen ? ('ZL: ' + last_checkin.zusatzleistungen) : '')}`;
    zl=0;
    return {
      logo: 'parsec.png',
      title: 'Anwesenheit ORF',
      sd: shared_data,
      summary: summary,
    }
  },
  computed: {
    name: function() {
      return client.get('user').vorname+" "+client.get('user').nachname;
    },
    checkout_date: function() {
      return moment().format('DD.MM.YYYY');
    }
  },
  methods: {
    click_back: async function(ev) {
      //console.log(points);
      const ret = await client.service('zeiterfassung').patch(shared_data.last_checkin.id, {
        checkout_sig: points.map(function(p) { return [ round(p.x, 1), round(p.y,1), round(p.pressure, 1) ] })
      });
      console.log(ret);
      if(ret)
        shared_data.last_checkin.checkout_sig = ret.checkout_sig;
      //console.log(shared_data.last_checkin.checkout_sig);
      this.$root.currentPage='app-checkout';
    },
    on_submit: async function(ev) {
      ev.preventDefault();
      const ret = await client.service('zeiterfassung').patch(shared_data.last_checkin.id, {
        checkout: true,
        checkout_sig: points.map(function(p) { return [ round(p.x, 1), round(p.y,1), round(p.pressure, 1) ] })
      });
      if(ret.checkout_ts) {
        points = [];
        points_new = [];
      }
      this.$root.currentPage='app-menu';
    },
    clear_sig: async function() {
      points = [];
      points_new = [];
      drawoptions.mousedown = false;
      var svg = document.querySelector("#csig");
      if(svg) {
        var path = svg.querySelector('path');
        path.removeAttribute("d");
      }
    }
  },
  mounted: function() {
    console.log('app-checkout-sig mounted');
    $('#app').addClass("checkout-sig");
    this.clear_sig();
    var pts = shared_data.last_checkin.checkout_sig;
    if(Array.isArray(pts)) {
      points = pts.map(function(p) { return { x: p[0], y: p[1], pressure: p[2] }; });
    }
    initSig();
    window.scrollTo(0, 0);
  },
  beforeDestroy: function() {
    this.clear_sig();
    $("canvas").off("mousedown touchstart mouseup touchend mousemove touchmove");
  }
});

Vue.component('app-list', {
  template: `
    <div class="container">
      <app-head v-bind:logo="logo" v-bind:title="title" :back="true" @back="back"></app-head>
      <div class="row-m row" v-for="record in records" style="background-color:#f1f1f1;margin-top:5px;">
        <div class="col-10 col-5-m " v-html="checkin_checkout(record)">
        </div>
        <div class="col-2 col-2-m text-right">
          {{ duration(record) }}
        </div>
        <div class="col-12 col-5-m">
          {{ record.produktionsort }}
        </div>
        <div class="col-3 col-2-m text-right">
          {{ record.km ? record.km + "&nbsp;km" : ''}}
        </div>
        <div class="col-3 col-2-m text-right">
          {{ record.mittepause ? record.mittepause + " min": '' }}
        </div>
        <div class="col-3-m">
        </div>
        <div class="col-12 col-5-m">
          {{ record.redakteurin_pl }}
        </div>
        <div class="col-12 col-7-m">
          {{ format_zulagen(record.zulagen) }}
        </div>
        <div class="col-12 col-5-m">
          {{ record.zusatzleistungen }}
        </div>
      </div>
    </div>`,
    data: function() {
      return {
        records: [],
        logo: 'parsec.png',
        title: 'Liste'
      }
    },
    computed: {
    },
    methods: {
      duration: function(record) {
        var co = record.checkout_ts ? moment(record.checkout_ts) : moment();
        return moment.duration(co.diff(record.checkin_ts)).format('d[t] h:mm', { trim: 'large', stopTrim: 'h' });
      },
      format_ts: function(s) {
        if(!s)
          return '/';
        return moment(s).format('DD.MM. HH:mm');
      },
      checkin_checkout: function(record) {
          if(moment(record.checkin_ts) >= moment('2018-09-01'))
            return(moment(record.checkout_ts).format('DD.MM.YYYY'))
          else
            return this.format_ts(record.checkin_ts)+"&nbsp;–&nbsp"+this.format_ts(record.checkout_ts);
      },
      format_zulagen: function(s) {
        return s.map(function(z) {
          return shared_data.zulagen.find(function(f) { return f.id == z.zulage_id }).text;
        }).join(', ');
      },
      back: function() {
        this.$root.currentPage='app-menu';
      }
    },
    mounted: async function() {
      const checkins = await client.service('zeiterfassung').find({ query: { user_id: client.get('user').id, $sort: { checkin_ts: -1 }, $limit: 90 } });
      if(checkins.data) {
        this.records = checkins.data;
      }
    }
  });

Vue.component('show-record', {
  props: [ 'record', 'index' ],
  computed: {
    checkout_sig_svg: function() {
      if(this.record.checkout_sig) {
        var m = this.record.checkout_sig.reduce(function(acc, cur) {
          return {
            max: { x:Math.max(cur[0], acc.max.x), y:Math.max(cur[1], acc.max.y) },
            min: { x:Math.min(cur[0], acc.min.x), y:Math.min(cur[1], acc.min.y) }
            }
          },
          { max: { x: -Infinity, y: -Infinity }, min: { x: Infinity, y: Infinity} });
        m.min.x -= 3;
        m.min.y -= 3;
        m.max.x += 3;
        m.max.y += 3;
        var scale = Math.min(200/(m.max.x-m.min.x), 60/(m.max.y-m.min.y));
        var path = this.record.checkout_sig.reduce(function(acc, cur) {
          var cmd=cur[2] ? 'L' : 'M';
          if(acc.last[0] == cur[0] && acc.last[1]+1 == cur[1]) // handle dots
            return { path: acc.path + `${cmd} ${(cur[0]-m.min.x)*scale} ${(cur[1]-m.min.y)*scale+1} `, last: cur };
          else
            return { path: acc.path + `${cmd} ${(cur[0]-m.min.x)*scale} ${(cur[1]-m.min.y)*scale} `, last: cur };
        }, { path: "", last: [ 0, 0, 0] });
        return '<svg width="200" height="60" xmlns="http://www.w3.org/2000/svg"><path fill="transparent" stroke="black" stroke-width="1" d="'+path.path+'"/></svg>';
      } else {
        return null;
      }
    },
    user_name: function() {
      const record = this.record;
      const u = shared_data.users_by_id[record.user_id];
      return u.name;
    },
    zulagen: function() {
      const record = this.record;
      return record.zulagen.map(function(z) {
        var zl=shared_data.zulagen.find(function(f) { return f.id == z.zulage_id });
        if(zl)
          return shared_data.zulagen.find(function(f) { return f.id == z.zulage_id }).text;
      }).join(', ');
    },
    duration: function() {
      const record = this.record;
      var co = record.checkout_ts ? record.checkout_ts : moment();
      if(!moment.isMoment(co))
        return '???';
      if(!moment.isMoment(record.checkin_ts))
        return '???';
      return (moment.duration(co.diff(record.checkin_ts)).subtract(parseInt(record.mittepause), 'minutes')).format('d[t] h:mm', { trim: 'large', stopTrim: 'h' });
    },
    checkin_ts_format: {
      get: function() {
        if(moment.isMoment(this.record.checkin_ts) && this.record.checkin_ts.isValid()) {
          return this.record.checkin_ts.format('DD.MM.YYYY HH:mm:ss');
        }
        return this.record.checkin_ts || "";
      },
      set: function(val){
        const m = moment(val, 'DD.MM.YYYY HH:mm:ss', true);
        if(m.isValid()) {
          this.record.checkin_ts = m;
        }
        else {
          this.record.checkin_ts = val;
        }
      }
    },
    checkout_ts_format: {
      get: function() {
        if(moment.isMoment(this.record.checkout_ts) && this.record.checkout_ts.isValid()) {
          return this.record.checkout_ts.format('DD.MM.YYYY HH:mm:ss');
        } else {
        }
        return this.record.checkout_ts;
      },
      set: function(val){
        const m = moment(val, 'DD.MM.YYYY HH:mm:ss', true);
        if(m.isValid()) {
          this.checkout_invalid=false;
        }
        else {
          this.checkout_invalid=true;
        }
      }
    },
  },
  template: `
    <tr :class="{ even: index % 2 == 0 }">
      <td>{{user_name}}</td>
      <td>{{checkin_ts_format}}</td>
      <td>{{checkout_ts_format}}</td>
      <td class="text-right">{{record.mittepause}}</td>
      <td class="text-right">{{duration}}</td>
      <td>{{record.produktionsort}}</td>
      <td class="text-right">{{record.km}}</td>
      <td class="text-right">{{record.mitfahrer}}</td>
      <td>{{record.diaet_art}}</td>
      <td class="text-right">{{record.diaet_anzahl}}</td>
      <td class="text-right">{{record.diaet_betrag}}</td>
      <td class="nw">{{zulagen}}</td>
      <td class="nw">{{record.zusatzleistungen}}</td>
      <td class="nw">{{record.redakteurin_pl}}</td>
      <td class="text-right">{{record.checkout_sig && record.checkout_sig.length}}</td>
      <td v-html="checkout_sig_svg"></td>
    </tr>`
});

Vue.component('edit-record', {
  props: {
    record: {
      default: {}
    },
  },
  data: function() {
    return {
      checkin_invalid: false,
      checkout_invalid: false,
      delete_confirm: false,
      zulagen_edit: false,
    };
  },
  template: `
      <form class="edit-record" v-bind:id="'edit-record-' + record.id" :class="{ new: record.id === 'new' }">
        <div class=" " title="User">{{user_name}}</div>
        <div class=" " title="Check-in">
          <input type="text" v-model="checkin_ts_format" class="field" :class="{ mark: checkin_invalid }" style="z-index:1"></input><span style="margin-top:5px;position:absolute;">—</span>
        </div>
        <div class=" " title="Check-out" style="z-index:2">
          <input type="text" v-model="checkout_ts_format" class="field" :class="{ mark: checkout_invalid }"></input>
        </div>
        <div class="" title="Mittepause">
          <input type="number" v-model="record.mittepause" step="30" min="0" max="120" style="" class="text-right field" style="padding-right:3ex"></input><span style="margin-left:-3ex;">min</span>
        </div>
        <div class="  text-right" title="Dauer">
          {{ duration }}
        </div>
        <div class=" " title="Name DTL / PL">
          <input type="text" v-model="record.redakteurin_pl" class="field"></input>
        </div>
        <div class="signature" title="Unterschrift" v-on:click="remove_sig" v-html="checkout_sig_svg">
        </div>
        <div>
        </div>
        <div style="grid-column: span 2" title="Ort">
          <input type="text" v-model="record.produktionsort" class="field"></input>
        </div>
        <div class="" title="gefahrene km">
          <input type="number" v-model="record.km" style="padding-right:3ex" class="text-right field"></input><span style="margin-left:-3ex;">km</span>
        </div>
        <div class="  text-right" title="MitfahrerInnen">
          <input type="number" v-model="record.mitfahrer" class="text-right field"></input>
        </div>
        <div>
        </div>
        <div title="Zusatzleistungen">
          <input type="text" v-model="record.zusatzleistungen" class="field"></input>
        </div>
        <div title="Diät art">
          <select v-model="record.diaet_art" class="field">
            <option value=""></option>
            <option value="1">Wien</option>
            <option value="2">Österreich</option>
            <option value="2">Ausland</option>
          </select>
        </div>
        <div class="text-right" title="Diät Anzahl">
          <input type="number" v-model="record.diaet_anzahl" step="0.5" class="text-right field"></input>
        </div>
        <div class="text-right" title="Diät Betrag (netto, einzel)">
          <input type="number" v-model="record.diaet_betrag" step="0.01" class="text-right field"></input>
        </div>
        <div>
        </div>
        <div class="zulagen" title="Zulagen">
          {{ zulagen }}
        </div>
        <div class="controls">
          <div class=" " title="Überprüft">
            <span class="checkbox">
              <label v-bind:for="'check-'+record.id">geprüft</label>
              <input type="checkbox" v-bind:id="'check-' + record.id" v-model="record.checked"></input>
            </span>
          </div>
          <div class=" ">
            <button type="button" v-on:click="save_record" v-if="record.changed">sichern</button>
          </div>
          <div class=" ">
            <button type="button" v-on:click="discard_changes" v-if="record.changed">verwerfen</button>
          </div>
          <div class=" ">
            <button type="button" v-on:click="delete_confirm = !delete_confirm">löschen</button>
          </div>
          <div class=" ">
            <button type="button" v-on:click="edit_zulagen" aria-pressed="true">zulagen</button>
          </div>
        </div>
        <div class="zulagen-editor" v-if="zulagen_edit">
          <app-zulagen v-bind:zulagen="record.zulagen" v-on:change="record.changed=true"></app-zulagen>
          <button type="button" v-on:click="zulage_add" class="button button-primary button-small"><font-awesome-icon icon="plus" /></button>
        </div>
        <div class="delete-confirm" v-if="delete_confirm">
          <button type="button" v-on:click="remove_record" class="button button-primary button-small button-red">löschen</button>
          <button type="button" v-on:click="delete_confirm = false" class="button button-primary button-small">abbrechen</button>
        </div>
      </form>`,
  computed: {
    user_name: function() {
      const record = this.record;
      const u = shared_data.users_by_id[record.user_id];
      return u.name;
    },
    checkout_sig_svg: function() {
      if(this.record.checkout_sig) {
        var m = this.record.checkout_sig.reduce(function(acc, cur) {
          return {
            max: { x:Math.max(cur[0], acc.max.x), y:Math.max(cur[1], acc.max.y) },
            min: { x:Math.min(cur[0], acc.min.x), y:Math.min(cur[1], acc.min.y) }
            }
          },
          { max: { x: -Infinity, y: -Infinity }, min: { x: Infinity, y: Infinity} });
        m.min.x -= 3;
        m.min.y -= 3;
        m.max.x += 3;
        m.max.y += 3;
        var scale = Math.min(200/(m.max.x-m.min.x), 60/(m.max.y-m.min.y));
        var path = this.record.checkout_sig.reduce(function(acc, cur) {
          var cmd=cur[2] ? 'L' : 'M';
          if(acc.last[0] == cur[0] && acc.last[1]+1 == cur[1]) // handle dots
            return { path: acc.path + `${cmd} ${(cur[0]-m.min.x)*scale} ${(cur[1]-m.min.y)*scale+1} `, last: cur };
          else
            return { path: acc.path + `${cmd} ${(cur[0]-m.min.x)*scale} ${(cur[1]-m.min.y)*scale} `, last: cur };
        }, { path: "", last: [ 0, 0, 0] });
        return '<svg style="width:100%" xmlns="http://www.w3.org/2000/svg"><path fill="transparent" stroke="black" stroke-width="1" d="'+path.path+'"/></svg>';
      } else {
        return null;
      }
    },
    duration: function() {
      const record = this.record;
      var co = record.checkout_ts ? record.checkout_ts : moment();
      if(!moment.isMoment(co))
        return '???';
      if(!moment.isMoment(record.checkin_ts))
        return '???';
      return (moment.duration(co.diff(record.checkin_ts)).subtract(parseInt(record.mittepause), 'minutes')).format('d[t] h:mm', { trim: 'large', stopTrim: 'h' });
    },
    zulagen: function() {
      const record = this.record;
      return record.zulagen.map(function(z) {
        var zl=shared_data.zulagen.find(function(f) { return f.id == z.zulage_id });
        if(zl)
          return shared_data.zulagen.find(function(f) { return f.id == z.zulage_id }).text;
      }).join(', ');
    },
    checkin_ts_format: {
      get: function() {
        return (moment.isMoment(this.record.checkin_ts) && this.record.checkin_ts.isValid()) ? this.record.checkin_ts.format('DD.MM.YYYY HH:mm:ss') : this.record.checkin_ts;
      },
      set: function(val){
        const m = moment(val, 'DD.MM.YYYY HH:mm:ss', true);
        if(m.isValid()) {
          this.record.checkin_ts = m;
          this.checkin_invalid=false;
        }
        else {
          this.record.checkin_ts = val;
          this.checkin_invalid=true;
        }
      }
    },
    checkout_ts_format: {
      get: function() {
        if(moment.isMoment(this.record.checkout_ts) && this.record.checkout_ts.isValid()) {
          this.checkout_invalid = false;
          return this.record.checkout_ts.format('DD.MM.YYYY HH:mm:ss');
        } else {
          this.checkout_invalid = true;
        }
        return this.record.checkout_ts;
      },
      set: function(val){
        const m = moment(val, 'DD.MM.YYYY HH:mm:ss', true);
        if(m.isValid()) {
          this.record.checkout_ts = m;
          this.checkout_invalid=false;
        }
        else {
          this.record.checkout_ts = val;
          this.checkout_invalid=true;
        }
      }
    },
  },
  methods: {
    edit_zulagen: async function() {
      this.zulagen_edit = (!this.zulagen_edit);
    },
    zulage_add: async function(ev) {
      this.record.zulagen.push( { zeiterfassung_id: this.record.id, zulage_id: null } );
      this.$nextTick(function() {
        var zl_els = document.getElementsByName('zulagen');
        zl_els[zl_els.length-1].focus();
      });
    },
    changed: function() {
      this.record.changed=true;
    },
    remove_sig: function() {
      this.record.checkout_sig = [];
      this.record.changed=true;
    },
    save_record: async function() {
      console.log(this.record);
      if(this.record.diaet_art === '')
        this.record.diaet_art = null;
      var data = {};
      Object.assign(data, this.record);
      if(moment.isMoment(data.checkin_ts) && data.checkin_ts.isValid())
        data.checkin_ts = data.checkin_ts.format();
      else
        data.checkin_ts = null;
      if(moment.isMoment(data.checkout_ts) && data.checkout_ts.isValid())
        data.checkout_ts = data.checkout_ts.format();
      else
        data.checkout_ts = null;
      if(this.record.id === 'new') {
        data.id = null;
        var new_record = await client.service('zeiterfassung').create(data);
        this.$emit('remove', this.record.id);
        var el = document.getElementById('edit-record-'+new_record.id);
        el.querySelector('input').focus();
        el.scrollIntoView();
      } else if(!isNaN(parseFloat(this.record.id))) {
          var rec = await client.service('zeiterfassung').patch(data.id, data);
      }
      this.record.changed = false;
    },
    remove_record: async function() {
      console.log(this.record);
      if(isNaN(parseFloat(this.record.id))) {
        this.$emit('remove');
      } else {
        var rec = await client.service('zeiterfassung').remove(this.record.id);
      }
    },
    discard_changes: async function() {
      console.log(this.record);
      if(isNaN(parseFloat(this.record.id))) {
        this.$emit('remove');
      } else {
        var rec = await client.service('zeiterfassung').get(this.record.id)
        rec.checkin_ts = moment(rec.checkin_ts);
        rec.checkout_ts = rec.checkout_ts ? moment(rec.checkout_ts) : null;
        Object.assign(this.record, rec);
        this.record.changed = false;
      }
    },
  },
});

Vue.component('find-user', {
  template: `
      <div class="find-user">
        <form @submit.prevent="search"><input type="search" placeholder="Namen suchen" id="user-filter" v-model="user_filter" class="field"></input></form>
        <div>
          <button v-if="shownone" type="button" v-on:click="on_click(0)">-- kein Filter --</button>
        </div>
        <div v-for="user in filtered_users" :key="user.id">
          <button type="button" v-on:click="on_click(user.id)">{{user.vorname}} {{user.nachname}}</button>
        </div>
      </div>`,
  data: function() {
    return {
      user_filter: '',
    }
  },
  methods: {
    search: function() {
      if(this.filtered_users.length == 1) {
        this.$emit('input', this.filtered_users[0].id);
      }
    },
    on_click: function(id) {
      this.$emit('input', id);
    }
  },
  computed: {
    filtered_users: function() {
      var flt = new RegExp(this.user_filter, 'i');
      return this.users.filter(function(u) { return (u.name).match(flt);  } ).sort(function(a, b) {
        var an=a.nachname+a.vorname, bn=b.nachname+b.vorname;
        return an.localeCompare(bn, 'de-DE-u-co-phonebk');
      });
    },
  },
  props: ['users', 'value', 'shownone'],
  mounted: function() {
    this.$el.querySelector('#user-filter').focus();
  }
});

Vue.component('app-table', {
  template: `
    <div class="container-full">
      <app-head v-bind:logo="logo" v-bind:title="title" back="true" @back="back"></app-head>
      <div v-if="finduser" class="select-user" style="position:fixed;left:0;width:100%;height:100%;background:rgba(0,0,0,0.8);z-index:99;">
        <find-user v-if="finduser" :users="users" @input="user_selected"></find-user>
      </div>
      <div class="admin-filter">
        <button type="button" v-on:click="show_filter = !show_filter">Filter</button><!-- <span>: {{name_filter ? sd.users_by_id[name_filter].name: '--'}}</span><span>: <input type="checkbox" v-model="hide_checked" id="hide_checked"></input><label for="hide_checked"> geprüfte ausblenden</label></span> -->
          <select v-model="month">
            <option value="0">-- alle --</option>
            <option v-for="(month,index) in months" :value="index+1">{{month}}</option>
          </select>
          <select v-model="year">
            <option value="2018">2018</option>
          </select>
        <div v-if="show_filter">
          <find-user :users="users" v-model="name_filter" @input="show_filter=false" shownone="true" ></find-user>
        </div>
      </div>
      <table class="full-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Check-in</th>
            <th>Check-out</th>
            <th>MP</th>
            <th>Dauer</th>
            <th>Ort</th>
            <th>km</th>
            <th>MF</th>
            <th>D</th>
            <th>DA</th>
            <th>DB</th>
            <th>Zulagen</th>
            <th>Anmerkungen</th>
            <th>DTL/PL</th>
            <th>Sig</th>
            <th>Unterschrift</th>
          </tr>
        </thead>
        <tbody>
          <show-record v-for="(record, index) in filter(records)" :key="record.id" :index="index" v-on:change="changed(record)" v-on:input="changed(record)" v-on:remove="records.splice(index,1)" :record="record"></show-record>
        </tbody>
      </table>
      <div id="admin-end">{{ records.length }} / {{ total_records }} <font-awesome-icon icon="spinner" :class="{'fa-spin': loading}"/></div>
    </div>`,
    data: function() {
      return {
        records: [],
        logo: 'parsec.png',
        title: 'Anwesenheit ORF',
        users: [],
        finduser: false,
        name_filter: '',
        show_filter: false,
        hide_checked: false,
        loading: false,
        sd: shared_data,
        total_records: 9999999,
        limit: 100,
        skip: 0,
        month: 0,
        year: 2018
      }
    },
    computed: {
      months: function() {
        return Array.apply(0, Array(12)).map(function(_,i){return moment().month(i).format('MMMM')});
      },
    },
    methods: {
      filter: function(records) {
        var uc = this.hide_checked;
        var uid = this.name_filter;
        var month = this.month;
        return records.filter(function(r) { return ((!uid || r.user_id == uid) && (!uc || !r.checked) && (!month || (r.checkin_ts == null || r.checkin_ts.get('month')+1 == month) )) });
      },
      fetch: async function() {
        if(this.records.length < this.total_records) {
          this.loading=true;
          const checkins = await client.service('zeiterfassung').find({ query: { $sort: { checkin_ts: -1, id: -1 }, $limit: this.limit, $skip: this.skip } });
          //console.log("ze_fetch", checkins, checkVisible(el), this.records.length, this.total_records);
          const component = this;
          if(checkins.data) {
            checkins.data.map(function(c) {
              c.changed=false;
              c.checkin_ts=c.checkin_ts && moment(c.checkin_ts);
              c.checkout_ts=c.checkout_ts ? moment(c.checkout_ts) : null;
              var index = component.records.findIndex(function(r) { return r.id === c.id });
              if(index >= 0) {
                console.log("splice", index, component.records[index].id);
                component.records.splice(index, 1);
              } else {
                component.skip++;
              }
              component.records.push(c);
            });
          }
          this.total_records = checkins.total;
          this.$nextTick(function() {
            component.fetch();
          });
        } else {
          this.loading=false;
        }
      },
      back: function() {
        if(this.finduser)
          this.finduser = false;
        else
          this.$root.currentPage='app-menu';
      },
    },
    mounted: async function() {
      const users = await client.service('users').find({ query: { $limit: 500 }});
      shared_data.users_by_id = {};
      shared_data.users = users.data;
      users.data.map(function(u) { u.name=u.vorname+" "+u.nachname; shared_data.users_by_id[u.id]=u; });
      this.users = users.data;
      this.fetch();
    }
  });

Vue.component('app-admin', {
  template: `
    <div class="container-full">
      <app-head v-bind:logo="logo" v-bind:title="title" back="true" @back="back"></app-head>
      <div v-if="finduser" class="select-user" style="position:fixed;left:0;width:100%;height:100%;background:rgba(0,0,0,0.8);z-index:99;">
        <find-user v-if="finduser" :users="users" @input="user_selected"></find-user>
      </div>
      <div class="admin-filter">
        <button type="button" v-on:click="show_filter = !show_filter">Filter</button><span>: {{name_filter ? sd.users_by_id[name_filter].name: '--'}}</span><span>: <input type="checkbox" v-model="hide_checked" id="hide_checked"></input><label for="hide_checked"> geprüfte ausblenden</label></span>
        <div v-if="show_filter">
          <find-user :users="users" v-model="name_filter" @input="show_filter=false" shownone="true" ></find-user>
        </div>
      </div>
      <app-button :onclick="add_record" class="pad-bottom-15"><font-awesome-icon icon="plus" /></app-button>
      <div v-for="(record, index) in filter(records)" :key="record.id" :index="index" v-on:change="changed(record)" v-on:input="changed(record)">
        <edit-record v-bind:record="record" v-on:remove="records.splice(index,1)"></edit-record>
      </div>
      <div id="admin-end">{{ records.length }} / {{ total_records }} <font-awesome-icon icon="spinner" :class="{'fa-spin': loading}"/></div>
    </div>`,
    data: function() {
      return {
        records: [],
        logo: 'parsec.png',
        title: 'Check',
        users: [],
        finduser: false,
        name_filter: null,
        show_filter: false,
        hide_checked: false,
        loading: false,
        sd: shared_data,
        total_records: 9999999,
      }
    },
    computed: {
      filtered_users: function() {
        var uid = this.name_filer;
        return this.users.filter(function(u) { return (u.user_id === uid)  } ).sort(function(a, b) {
          var an=a.nachname+a.vorname, bn=b.nachname+b.vorname;
          if(an < bn) return -1;
          if(an > bn) return 1;
          return 0 });
      }
    },
    methods: {
      filter: function(records) {
        var uc = this.hide_checked;
        var uid = this.name_filter;
        return records.filter(function(r) { return ((!uid || r.user_id == uid) && (!uc || !r.checked) ) });
      },
      user_selected: async function(userid) {
        console.log(userid);
        this.finduser=false;
        var index = this.records.findIndex(function(r) { return r.id=='new'; } );
        if(index < 0) {
          this.records.unshift({id:'new', user_id: userid, zulagen: [], checkout_ts: null, checkin_ts: null, changed: true})
          if(this.name_filter)
            this.name_filter=userid;
        } else {
          if(this.name_filter)
            this.name_filter=this.records[index].user_id;
          this.$nextTick(function() {
            var el = this.$el.querySelector('#edit-record-' + this.records[index].id);
            el.querySelector('input').focus();
            el.scrollIntoView();
          });
        }
      },
      add_record: async function() {
        this.finduser=true;
      },
      changed: async function(record) {
        record.changed = true;
      },
      back: function() {
        if(this.finduser)
          this.finduser = false;
        else
          this.$root.currentPage='app-menu';
      },
      scroll: async function() {
        if(this.scrolling)
          return;
        this.scrolling = true;
        //console.log("scroll fetch ans ", document, document.scrollTop, document.offsetHeight);
        //FIXME: no support by ie?
        //console.log(document.scrollingElement.scrollTop == document.scrollingElement.scrollHeight - document.scrollingElement.clientHeight);
        if($(window).scrollTop() == $(document).height() - $(window).height()) {
          console.log("fetch more data");
          await this.ze_fetch();
        }
        this.scrolling = false;
      },
      on_patched: function(record) {
        console.log('record patched', record);
        var index = this.records.findIndex(function(r) {return r.id == record.id});
        if(index >= 0) {
          record.changed = false;
          record.checkin_ts = moment(record.checkin_ts);
          record.checkout_ts = record.checkout_ts ? moment(record.checkout_ts) : null;
          Object.assign(this.records[index], record);
        }
      },
      on_created: function(record) {
        console.log('record created', record);
        var ci = moment(record.checkin_ts);
        record.checkin_ts = ci;
        record.checkout_ts = record.checkout_ts ? moment(record.checkout_ts) : null;
        //var index = this.records.findIndex(function(r) { return r.checkin_ts < ci });
        //console.log(index);
        //this.records.splice(0, 1);
        //this.records.splice(index, 0, record);
        this.records.unshift(record);
      },
      on_removed: function(record) {
        console.log('record removed', record);
        var index = this.records.findIndex(function(r) {return r.id == record.id});
        if(index >= 0) {
          this.records.splice(index,1);
          //FIXME: this is a hack, how to keep in sync?
          this.skip--;
        }
      },
      ze_fetch: async function() {
        let el = document.getElementById('admin-end');
        var that = this;
        if(checkVisible(el) && this.records.length < this.total_records) {
          this.loading=true;
          const checkins = await client.service('zeiterfassung').find({ query: { $sort: { checkin_ts: -1, id: -1 }, $limit: this.limit, $skip: this.skip } });
          //console.log("ze_fetch", checkins, checkVisible(el), this.records.length, this.total_records);
          const component = this;
          if(checkins.data) {
            checkins.data.map(function(c) {
              c.changed=false;
              c.checkin_ts=moment(c.checkin_ts);
              c.checkout_ts=c.checkout_ts ? moment(c.checkout_ts) : null;
              var index = component.records.findIndex(function(r) { return r.id === c.id });
              if(index >= 0) {
                console.log("splice", index, component.records[index].id);
                component.records.splice(index, 1);
              } else {
                component.skip++;
              }
              component.records.push(c);
            });
          }
          this.total_records = checkins.total;
          this.$nextTick(function() {
            that.ze_fetch();
          });
        } else {
          this.loading=false;
        }
      },
    },
    mounted: async function() {
      client.service('zeiterfassung').on('created', this.on_created);
      client.service('zeiterfassung').on('patched', this.on_patched);
      client.service('zeiterfassung').on('removed', this.on_removed);
      const users = await client.service('users').find({ query: { $limit: 500 }});
      shared_data.users_by_id = {};
      shared_data.users = users.data;
      users.data.map(function(u) { u.name=u.vorname+" "+u.nachname; shared_data.users_by_id[u.id]=u; });
      this.users = users.data;
      //console.log(users);
      this.limit = 10;
      this.skip = 0;
      await this.ze_fetch();
      if('IntersectionObserver' in window) {
        // the callback function that will be fired
        // when the element apears in the viewport
        // because it fires on initialization
        // we have to check for change.isIntersecting parameter
        var component = this;
        function onEntry(entry) {
          entry.forEach((change) => {
            if(change.isIntersecting) {
              //change.target.classList.add('visible');
              console.log("#admin-end");
              component.ze_fetch();
            }
          });
        }

        let options = {
          threshold: [0.5]
        };

        let observer = new IntersectionObserver(onEntry, options);

        let el = document.getElementById('admin-end');
        observer.observe(el);
      } else {
        window.addEventListener('scroll', this.scroll, false);
      }
      window.shared_data.records = this.records;
    },
    beforeDestroy: function() {
      window.removeEventListener('scroll', this.scroll);
      client.service('zeiterfassung').removeListener('created', this.on_created);
      client.service('zeiterfassung').removeListener('patched', this.on_patched);
      client.service('zeiterfassung').removeListener('removed', this.on_removed);
    }
/*
    updated: async function() {
      var component = this;
      this.$nextTick(function () {
        $('.datetimepicker').datetimepicker({format: 'YYYY-MM-DDTHH:mm:ss', formatTime:'HH:mm', dayOfWeekStart: 1 , onChangeDateTime: function(dp,$input) { console.log($input.parent().context); console.log(component); component.$emit('input'); $($input.parent().context).trigger('change'); }  });
      });
    }
*/
  });

const showRegister = (error = {}) => {
    $('#app').html(registerHTML);

    var p1 = $('#password');
    var p2 = $('#password2');
    var re = /[a-z]\d|\d[a-z]/i;

    p1.on('input', function() {
      if(re.test(p1.val())) {
        p1[0].setCustomValidity("");
        if(p1.val() != p2.val())
          p2[0].setCustomValidity("Passwörter stimmen nicht überein!")
        else
          p2[0].setCustomValidity("")
      } else {
        p1[0].setCustomValidity("Min. 6 Zeichen, davon min. eine Ziffer und ein Buchstabe.")
      }
    });
    p2.on('input', function() {
      if(re.test(p2.val())) {
        p2[0].setCustomValidity("");
        if(p1.val() != p2.val())
          p2[0].setCustomValidity("Passwörter stimmen nicht überein!")
        else
          p2[0].setCustomValidity("")
      } else {
        p2[0].setCustomValidity("Min. 6 Zeichen, davon min. eine Ziffer und ein Buchstabe.")
      }
    });
    $(document).on('click', '#resend', async () => {
      try {
        await client.service('authManagement').create({action: 'resendVerifySignup', value: { email: $('#email').val() }});
        $('#errors').html(`
          <div class="flex flex-row flex-1 pad-top-15">
            <div class="flex flex-column col col-12">
              Bestätigunsmail verschickt.
            </div>
          </div>`);
      } catch(error) {
        var msg=error.message;
        if(error.message === 'User is already verified.') {
          msg='Diese Adresse ist bereits bestätigt. Sie können sich hier einloggen: <a href="https://an.parsec.co.at">https://an.parsec.co.at</a>';
        }
        $('#errors').html(`
          <div class="flex flex-row flex-1 pad-top-15">
            <div class="flex flex-column col col-12">
              Fehler: ${msg}
            </div>
          </div>`);
      }
    })
    .on('submit', '#registerform', async ev => {
      ev.preventDefault();
      const userdata = getRegistrationData();

      try {
        var ret = await client.service('users').create(userdata);
        $('#content').html(`
          <div class="flex flex-row pad-top-15">
            <div class="flex flex-column col col-12">
              <p>Ihre Daten wurden gespeichert. Bitte bestätigen Sie die Registrierung über den Link, den wir Ihnen zugeschickt haben.</p>
            </div>
          </div>
`);
      } catch (error) {
        console.log(error);
        $('#errors').html(`
            <div class="flex flex-row flex-1 pad-top-15">
              <div class="flex flex-column col col-12">
                Diese Adresse ist bereits registriert. Sie können das Bestätigungsmail erneut senden lassen.
              </div>
            </div>
            <div class="flex flex-row flex-1 pad-top-5">
              <div class="flex flex-column col col-12">
                <button type="button" id="resend" class="button-primary">Erneut Senden</button>
              </div>
            </div>
  `);
        return false;
      }
      console.log(ret);
    })
};

// Show the login page
const showLogin = (error = {}) => {
  if($('.login').length) {
    console.log(error.message);
    $('.errors').html(`<p>Fehler: ${error.message}</p>`);
    if(error.message === 'Invalid login') {
      vueApp.loginerror=error.message;
    }
    $('.errors').addClass('flash');
    setTimeout(function() {
      $('.errors').removeClass('flash');
    }, 500);
  } else {
    vueApp.currentPage='app-login';
  }
};

function geo_success(position) {
  last_pos = position;
  var info='[]';
  if(position.coords.latitude > 47.889 && position.coords.latitude < 47.899 && position.coords.longitude > 16.640 && position.coords.longitude < 16.669) {
    info=' [Doki]';
  }
  if(position.coords.latitude > 48.175 && position.coords.latitude < 48.180 && position.coords.longitude > 16.288 && position.coords.longitude < 16.293) {
    info=' [ORF]';
  }
  $('#pos_data').html(round(position.coords.latitude,3) + ', ' + round(position.coords.longitude,3) + ' (' + round(position.coords.accuracy,0) + 'm)' + info);
}

function geo_error() {
  $('#pos_data').html('nicht ermittelbar.');
}

var geo_options_la = {
  enableHighAccuracy: false,
  maximumAge        : 30000, 
  timeout           : 2000
};

var geo_options = {
  enableHighAccuracy: true,
  maximumAge        : 30000, 
  timeout           : 27000
};

async function getLastCheckin() {
  try {
    const checkins = await client.service('zeiterfassung').find({ query: { user_id: client.get('user').id, checkout_ts: null, checkin_ts: { $ne: null }, $sort: { id: -1 }, $limit: 1 } });
    if(checkins.data.length) {
      shared_data.last_checkin = checkins.data[0];
    } else {
      shared_data.last_checkin = { checkin_ts: 0 };
    }
  } catch(error) {
    console.log(error);
    if(error.name === 'NotAuthenticated') {
      login();
    }
  }
}

const getZulagen = async () => {
  var zulagen = await client.service('zulagen').find();
  shared_data.zulagen = zulagen.data;
}

vueApp = new Vue({
  el: "#app",
  props: ['loginerror'],
  data: {
    currentPage: 'app-loading',
    visible: true,
  }
});

const showMenu = async () => {
  console.log("showMenu");
  last_pos = null;
  vueApp.currentPage ="app-menu";

  //var wpid = navigator.geolocation.getCurrentPosition(geo_success, geo_error, geo_options_la);
  await getZulagen();

  //var wpid = navigator.geolocation.watchPosition(geo_success, geo_error, geo_options);
};

const getRegistrationData = () => {
  const user = {
    vorname: $('[name="vorname"]').val(),
    nachname: $('[name="nachname"]').val(),
    email: $('[name="email"]').val(),
    password: $('[name="password"]').val()
  };
  return user;
};

client.on('reauthentication-error', showLogin);


const login = async credentials => {
  console.log("login");
  try {
    var payload = {};
    if(!credentials) {
      console.log("!credentials");
      // Try to authenticate using the JWT from localStorage
    } else {
      //console.log(credentials);
      // If we get login information, add the strategy we want to use for login
      payload = Object.assign({ strategy: 'local', field: 'id' }, credentials);
    }
    var ret = await client.authenticate(payload)
      .then(response => {
          console.log('Authenticated!', response);
          return client.passport.verifyJWT(response.accessToken);
      })
      .then(jwtpayload => {
          console.log('JWT Payload', jwtpayload);
          return client.service('users').get(jwtpayload.userId);
      })
      .then(user => {
          client.set('user', user);
          return user;
      }).then(user => {
        if (credentials && window.PasswordCredential) {
          credentials.id = credentials.email;
          var c = new PasswordCredential(credentials);
          return navigator.credentials.store(c);
        }
      });
    console.log(ret);

    showMenu();
  } catch(error) {
    // If we got an error, show the login page
    if(window.location.pathname === '/register') {
      showRegister(error);
    } else {
      console.log(error);
      showLogin(error);
    }
  }
};

function round(number, precision) {
  var shift = function (number, precision, reverseShift) {
    if (reverseShift) {
      precision = -precision;
    }  
    var numArray = ("" + number).split("e");
    return +(numArray[0] + "e" + (numArray[1] ? (+numArray[1] + precision) : precision));
  };
  return shift(Math.round(shift(number, precision, false)), precision, true);
}

$(document)
  .on('visibilitychange', async ev => {
    vueApp.$data.visible = !document.hidden;
  });

$(document).ajaxError(function(event, request, settings) {
  console.log('ajaxError', event, request, setting);
});


if(window.location.pathname === '/register') {
  showRegister();
} else {
  login();
}

function stroke_path(timestamp) {
  //console.log('stroke_path()');
  var p;
  var svg = document.querySelector("#csig");
  var path = svg.querySelector('path');
  //console.log(path);
  var cp="";
  if(path)
    cp = path.getAttribute("d");
  else
    path = svg.append(document.createElement('path'));
  if(!cp)
    cp = '';
  //console.log(cp);
  while(p=points_new.shift()) {
    points.push(p);
    if(p.pressure) {
      cp += "L "+ p.x + " " + p.y + " ";
    } else {
      cp += "M "+ p.x + " " + p.y + " ";
    }
  }
  //var path = svg.querySelector('path');
  path.setAttribute('d', cp);
  //console.log('stroke_path() done.');
}

function initSig() {
  drawoptions.ratio = 1.0;
  points_new = points.concat(points_new);
  points = [];
  window.requestAnimationFrame(stroke_path);
  var svg = document.querySelector("#csig").parentNode;

  var ts = function(e) {
    e.preventDefault();
    e.stopPropagation();
    drawoptions.mousedown=true;
    if(e.type=="touchstart") {
      var rect = e.target.getBoundingClientRect();
      drawoptions.left = rect.left;
      drawoptions.top = rect.top;
      var cx = e.touches[0].clientX - drawoptions.left;
      var cy = e.touches[0].clientY - drawoptions.top;
    } else {
      var cx = e.offsetX;
      var cy = e.offsetY;
    }
    points_new.push(new Point(cx*drawoptions.ratio, cy*drawoptions.ratio, 0));
  }

  svg.addEventListener("mousedown", ts, {passive: false });
  svg.addEventListener("touchstart", ts, { passive: false });

  var te = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if(drawoptions.mousedown){
      if(e.type=="touchend") {
        var rect = e.target.getBoundingClientRect();
        drawoptions.left = rect.left;
        drawoptions.top = rect.top;
        var cx = e.changedTouches[0].clientX - drawoptions.left;
        var cy = e.changedTouches[0].clientY - drawoptions.top;
      } else {
        var cx = e.offsetX;
        var cy = e.offsetY;
      }
      points_new.push(new Point(cx*drawoptions.ratio, cy*drawoptions.ratio+1, 1));
      window.requestAnimationFrame(stroke_path);
    }
    drawoptions.mousedown = false;
  };

  svg.addEventListener("mouseup", te);
  svg.addEventListener("touchend", te);

  var tm = function(e){
/*
    e.preventDefault();
    e.stopPropagation();
*/
    //$('#summary').html("down=" + drawoptions.mousedown + " force=" + e.originalEvent.touches[0].force);
    //console.log(e);
    if(drawoptions.mousedown){
      if(e.type=="touchmove") {
        var rect = e.target.getBoundingClientRect();
        drawoptions.left = rect.left;
        drawoptions.top = rect.top;
        var cx = e.touches[0].clientX - drawoptions.left;
        var cy = e.touches[0].clientY - drawoptions.top;
        var force = e.touches[0].force;
        if(!force || force < 0.1)
          force = 1;
      } else {
        var force = 1;
        var cx = e.offsetX;
        var cy = e.offsetY;
      }
      points_new.push(new Point(cx*drawoptions.ratio, cy*drawoptions.ratio, force));
      window.requestAnimationFrame(stroke_path);
    }
  }

  svg.addEventListener("mousemove", tm, { passive: true });
  svg.addEventListener("touchmove", tm, { passive: true });
}

function checkVisible(elm) {
  var rect = elm.getBoundingClientRect();
  var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}

window.client = client;
window.shared_data = shared_data;
